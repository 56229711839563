<script>
import PermissionMixin from '@/components/Permission/PermissionMixin'
import ActionDialog from '@/components/Dialogs/ActionConfirmationDialog.vue'
import { mapGetters, mapActions } from 'vuex'
import { convertToTargetUnit } from '@/components/Form/converter'
import LayoutMixin from '@/components/LayoutMixin'

export default {
  name: 'FazendaList',

  mixins: [LayoutMixin, PermissionMixin],

  data() {
    return {
      excludingId: null,
      seasonAccount: null,
      countEnabled: null,
      enabled: null,
      dialogFarmDelete: false,
      dialogFarmEnableDisable: false,
      dialogEnableLimitExceeded: false,
      dialogDisableFarm: false,
    }
  },

  components: {
    ActionDialog,
  },

  async created() {
    await this.getSeasonAccount()
  },

  mounted() {
    this.fetchFarms(this.$route?.query?.fazenda_id)
  },

  computed: {
    ...mapGetters('farms', ['farms', 'hasNoFarms']),
    ...mapGetters('user', ['currentUser']),
    showMenu() {
      return (
        this.hasPermission(this.$p.plot.list) ||
        this.hasPermission(this.$p.farm.delete) ||
        this.hasPermission(this.$p.farm.edit)
      )
    },
    mobile() {
      const breakpoint = this.$vuetify.breakpoint.name
      return ['sm', 'xs'].includes(breakpoint)
    },
    farmColumns() {
      const breakpoint = this.$vuetify.breakpoint.name
      if (['sm'].includes(breakpoint)) return 4
      else return 12
    },
    countEnabledAsc() {
      return this.countEnabled == 0 ? 1 : this.countEnabled + 1
    },
    accountName() {
      return this.currentUser?.name
    },
    contactWhatsapp() {
      return (
        process.env.VUE_APP_RELATIONSHIP_WHATSAPP +
        this.$t('contato.msg_whatsapp_limit_enable_exceeded', [
          this.accountName,
        ])
      )
    },
  },

  methods: {
    ...mapActions('farms', [
      'fetchFarms',
      'setFarm',
      'deleteFarm',
      'enableDisableFarm',
    ]),
    defineImageFarm(farm) {
      return farm.enabled
        ? {
            alt: 'enabled-farm',
            src: require('@/assets/img/placeholders/img-farm-placeholder-768.webp'),
          }
        : {
            alt: 'disabled-farm',
            src: require('@/assets/img/placeholders/img-disabled-farm-200.webp'),
          }
    },
    disabledFarm(farm) {
      return !farm.enabled
    },
    showArea(area) {
      return convertToTargetUnit(area, 'area')
    },
    createFarm() {
      this.$emit('open-modal-farms', null)
    },
    editFarm(farmId) {
      this.$emit('open-modal-farms', { id: farmId })
    },
    openModalPlot(farm) {
      this.$emit('open-modal-plot', farm)
    },
    selecionaFazenda(id) {
      this.setFarm(id)

      this.$router.push({
        path: 'my_farm',
        query: {
          fazenda_id: id,
        },
      })

      window.location.reload()
    },
    async getSeasonAccount() {
      const response = await this.$api.seasonAccount?.get()
      this.seasonAccount = await response.data
      this.countEnabled = this.seasonAccount?.count_enabled
    },
    closeDeleteConfirmation() {
      this.dialogFarmDelete = false
      this.excludingId = null
    },
    showDeleteConfirmation(farmId) {
      this.excludingId = farmId
      this.dialogFarmDelete = true
    },
    closeEnableConfirmation() {
      this.dialogFarmEnableDisable = false
      this.farmId = null
    },
    closeDisableConfirmation() {
      this.dialogDisableFarm = false
      this.farmId = null
    },
    showEnableDisableConfirmation(farmId) {
      this.farmId = farmId
      this.dialogFarmEnableDisable = this.countEnabled < 2 ? true : false
    },
    showDisableConfirmation(farmId) {
      this.farmId = farmId
      this.dialogDisableFarm = true
    },
    showDialogEnableExceededConfirmation() {
      this.dialogFarmEnableDisable = false
      this.dialogEnableLimitExceeded = true
    },
    closeDialogEnableExceededConfirmation() {
      this.dialogFarmEnableDisable = false
      this.dialogEnableLimitExceeded = false
    },
    async farmEnableDisable(farm) {
      if (!farm.enabled) {
        this.showEnableDisableConfirmation(farm.id)
      } else {
        this.showDisableConfirmation(farm.id)
      }

      this.setEnableAndCountDisableFarm(farm.id)
      await this.getSeasonAccount()

      if (this.countEnabled >= 2 && !this.enabled) {
        this.showDialogEnableExceededConfirmation()
      }
    },
    async farmEnable() {
      this.setEnableAndCountDisableFarm(this.farmId)

      await this.enableDisableFarm({
        farmId: this.farmId,
      })

      await this.getSeasonAccount()

      if (this.enabled) {
        this.$root.$emit(
          'notify',
          'success',
          this.$t('fazenda.disable.success')
        )
        this.closeEnableConfirmation()
        this.closeDisableConfirmation()
        return
      }

      this.$root.$emit('notify', 'success', this.$t('fazenda.enable.success'))
      this.closeEnableConfirmation()
      this.closeDisableConfirmation()
    },
    setEnableAndCountDisableFarm(farmId) {
      const farm = this.farms?.find((farm) => farm.id === farmId)

      this.enabled = farm?.enabled
    },
    showButtonEdit(farm) {
      return farm.enabled && this.hasPermission(this.$p.farm.edit)
    },
    async farmDelete() {
      await this.deleteFarm({
        routeFarmId: this.$route?.query?.fazenda_id,
        excludingFarmId: this.excludingId,
      })

      this.$root.$emit('notify', 'success', this.$t('fazenda.excluir.sucesso'))

      this.closeDeleteConfirmation()

      if (this.hasNoFarms) {
        this.$router.replace('/my_farm')
      }
      window.location.reload()
    },
    getInTouch() {
      window.open(this.contactWhatsapp).focus()
      this.dialogEnableLimitExceeded = false
    },
  },
}
</script>

<template>
  <v-item-group class="distance-items">
    <v-row justify="center" align="center">
      <v-col
        v-if="!isMobile"
        align="center"
        justify="center"
        md="3"
        :cols="farmColumns"
      >
        <v-item>
          <v-card
            :class="{
              'card-mobile rounded-lg': mobile,
              'card-desktop rounded': !mobile,
            }"
            @click="createFarm"
          >
            <v-col cols="12" md="8" class="pt-8">
              <v-img
                alt="tractor"
                max-width="110px"
                src="icons/ic-tractor-icon.svg"
              />
            </v-col>
            <v-row justify="center" align="center">
              <v-col md="12" sm="4">
                <span class="add-title-main">
                  {{ $t('fazenda.create_farm') }}
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-item>
      </v-col>

      <v-col
        v-for="fazenda in farms"
        :key="fazenda.id"
        :cols="farmColumns"
        md="3"
        justify="center"
        align="center"
      >
        <v-item>
          <v-card
            class="white center"
            :class="{
              'card-mobile rounded-lg': mobile,
              'card-desktop rounded': !mobile,
            }"
            @click="selecionaFazenda(fazenda.id)"
          >
            <v-img
              class="white--text"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
              :class="{
                'card-mobile': mobile,
                'card-desktop': !mobile,
              }"
              :alt="defineImageFarm(fazenda).alt"
              :src="defineImageFarm(fazenda).src"
            >
              <v-card-title class="pa-2">
                <v-spacer></v-spacer>
                <v-menu bottom left v-if="showMenu">
                  <template #activator="{ on }">
                    <v-btn dark icon v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item link v-if="showButtonEdit(fazenda)">
                      <v-list-item-title @click="editFarm(fazenda.id)">
                        <v-icon>mdi-pencil</v-icon> &nbsp;
                        {{ $t('editar') }}</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item
                      link
                      v-if="hasPermission($p.plot.list)"
                      @click="openModalPlot(fazenda)"
                    >
                      <v-list-item-title>
                        <v-icon>mdi-layers</v-icon> &nbsp;
                        {{ $t('talhoes.talhoes') }}
                      </v-list-item-title>
                    </v-list-item>
                    <v-tooltip right>
                      <template #activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          <v-list-item
                            v-if="hasPermission($p.farm.delete)"
                            @click="farmEnableDisable(fazenda)"
                            disabled
                          >
                            <span class="disable-item">
                              <v-list-item-title v-if="disabledFarm(fazenda)">
                                <v-icon class="icon-enable"
                                  >mdi-toggle-switch</v-icon
                                >
                                &nbsp;
                                {{ $t('enable') }}
                              </v-list-item-title>
                              <v-list-item-title v-else>
                                <v-icon>mdi-toggle-switch-off</v-icon>
                                &nbsp;
                                {{ $t('disable') }}
                              </v-list-item-title>
                            </span>
                          </v-list-item>
                        </div>
                      </template>
                      <span>
                        {{ $t('function_temporarily_unavailable') }}
                      </span>
                    </v-tooltip>

                    <v-list-item
                      v-if="hasPermission($p.farm.delete)"
                      @click="showDeleteConfirmation(fazenda.id)"
                    >
                      <v-list-item-title class="red--text">
                        <v-icon color="red">mdi-delete</v-icon> &nbsp;
                        {{ $t('excluir') }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-card-title>

              <v-card-text
                class="float-left text-left d-block white--text card-text-position pa-2"
              >
                <div v-if="disabledFarm(fazenda)" class="text-disable">
                  {{ $t('she_disabled') }}
                </div>

                <div class="font-weight-black">{{ fazenda.name }}</div>
                <div class="text--secundary">
                  {{
                    fazenda.location.city.name +
                    (fazenda.location.state.acronym != undefined
                      ? `/${fazenda.location.state.acronym}`
                      : '')
                  }}
                </div>
                <div class="text--secundary">
                  {{ showArea(fazenda.total_area) }}
                  {{ $unitMeasures['area'][$currentLanguage()] }}
                </div>
              </v-card-text>
            </v-img>
          </v-card>
        </v-item>
      </v-col>
    </v-row>

    <action-dialog
      :dialog="dialogFarmDelete"
      :text="$t('fazenda.excluir.alerta.mensagem')"
      :text-cancel="$t('cancelar')"
      :text-confirm="$t('excluir')"
      :cancel="closeDeleteConfirmation"
      :confirm="farmDelete"
      color-cancel="#8f8f8f"
      color-confirm="red"
    />

    <action-dialog
      :dialog="dialogFarmEnableDisable"
      :text="$t('fazenda.enable.warning', [countEnabledAsc])"
      :text-cancel="$t('no')"
      :text-confirm="$t('yes')"
      :cancel="closeEnableConfirmation"
      :confirm="farmEnable"
      color-cancel="#8f8f8f"
      color-confirm="#39af49"
    />

    <action-dialog
      :dialog="dialogEnableLimitExceeded"
      :text="$t('fazenda.enable.error')"
      :text-cancel="$t('cancelar')"
      :text-confirm="$t('get_in_touch')"
      :cancel="closeDialogEnableExceededConfirmation"
      :confirm="getInTouch"
      color-cancel="#8f8f8f"
      color-confirm="#39af49"
    />

    <action-dialog
      :dialog="dialogDisableFarm"
      :text="$t('fazenda.disable.warning')"
      :text-cancel="$t('no')"
      :text-confirm="$t('yes')"
      :cancel="closeDisableConfirmation"
      :confirm="farmEnable"
      color-cancel="#8f8f8f"
      color-confirm="#39af49"
    />
  </v-item-group>
</template>

<style>
.distance-items {
  padding: 0px 37px 0px 11px;
}
.add-title-main {
  font-family: Rubik, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  margin-top: -20px;
  color: #0e180c;
}

.text-disable {
  width: 90px;
  background: #788476;
  border-radius: 8px;
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin: 0px -6px;
}

.btn-top {
  height: 100% !important;
  width: 100% !important;
}

.btn-bottom {
  height: 100% !important;
  width: 100% !important;

  color: #1e1;
}

.card-text-position {
  position: absolute;
  bottom: 0;
}

.block {
  background-color: red;
}

.card-mobile {
  height: 150px;
  width: 300px;
}

.card-desktop {
  height: 200px;
  width: 200px;
}

.icon-enable {
  color: #62cc70 !important;
}

.disable-item {
  color: #939b94c0 !important;
}

@media (min-width: 1264px) {
  .add-card-create-farm {
    padding-right: 47px !important;
    padding-left: 47px !important;
  }
}

@media (min-width: 1904px) {
  .add-card-create-farm {
    padding-right: 118px !important;
    padding-left: 118px !important;
  }
}
</style>
